import React from 'react';

function Progress(props) {
    var emiratesList = props.progressData;
    var highestCount = 0;

    emiratesList.forEach((el) => {
        highestCount = (el.incident_count > highestCount) ? highestCount = el.incident_count : highestCount;
    })

    return (
        <div className="master-section-content">
            <div className="master-incident-list">
                <div className="incident-city">
                    {emiratesList && emiratesList.map(data => (
                        <p>{data.emirate_name}</p>
                    ))
                    }
                </div>
                <div className="incident-values">
                    <ul className="progressbar">
                        {emiratesList && emiratesList.map(data => (

                            <li className={data.id % 2 == 0 ? 'past' : 'current'}>
                                {highestCount != 0 ?
                                    <>
                                        <span className="bar" style={{ width: (data.incident_count * 100) / `${highestCount}` + '%' }}></span>
                                        <span className="number" style={{ left: (data.incident_count * 100) / `${highestCount}` + '%' }}>{data.incident_count != 0 ? data.incident_count : 'NA'}</span>
                                    </>
                                    :
                                    <span className="number" >NA</span>
                                }
                            </li>
                        ))

                        }

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Progress;