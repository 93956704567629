import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../Common/SideBar/Sidebar';
import Topbar from '../../Common/Header/Topbar';
import Flatpickr from "react-flatpickr";
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { getUrl } from '../../utils/Urlpath';
import { getToken } from '../../utils/Common';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { format, parseISO } from 'date-fns'

function Incidentreport() {
  let navigate = useNavigate();
  const pagename = "Incident Reports";
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const form = useRef(null);
  var sitName = localStorage.getItem("site_name")

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = sitName + ' - Incident Reports';
  }, [sitName]);
  const [date, setDate] = useState();
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [date4, setDate4] = useState();
  const [date5, setDate5] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const url = getUrl();
  let [incidentReportList, setPrimePhoneNumberList] = useState([]);
  let [artifactList, setArtifactList] = useState([]);
  let [locationList, setLocationList] = useState([]);
  let [emirateList, setEmirateList] = useState([]);
  let [personList, setPersonList] = useState([]);
  let [agentlist, setAgentList] = useState([]);
  let [menuconfig, setMenuConfig] = useState([]);
  const [reportname, SetIncidentTitle] = useState();
  const [reporttype, setReportType] = useState();
 

  const fetchData = () => {
    axios.get(url + 'basic-filter-details', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setPrimePhoneNumberList(response.data.ResponseData.data.primePhoneNumberList);
      setArtifactList(response.data.ResponseData.data.ArtifactList);
      setLocationList(response.data.ResponseData.data.locationList);
      setEmirateList(response.data.ResponseData.data.emirateList);
      setPersonList(response.data.ResponseData.data.personList);
    }).catch(error => {
    });
    axios.get(url + 'module-configs', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }

    }).then(response => {
      setMenuConfig(response.data.ResponseData.data.moduleConfigList);
    }).catch(error => {
    });
  }
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    fetchData();
    //eslint-disable-next-line
  }, []);



  useEffect(() => {
    axios.get(url + 'get-user-list', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setAgentList(response.data.ResponseData.userList);

    }).catch(error => {
    });
  }, [url]);

  const checkmaxlength = (str, maxlength) => {

    if (str.length > maxlength) {
      return true;
    }
  }

  const downloadFile = () => {


    if (!values.report_type) {
      setReportType('Please Select Report Type');
      //return false;
    } else {
      setReportType('');
      //return true;
    }
    if (!values.report_name || values.report_name === 'undefined') {
      SetIncidentTitle('Please Enter Report Name');
      //return false;
    }
    else if (checkmaxlength(values.report_name, 100)) {
      SetIncidentTitle('Report Name exceeds limit of 100 characters');
      return false;
    } else {
      SetIncidentTitle('');
      //return true;
    }
    let form_data = new FormData();
    if (values.report_name === undefined) {
      SetIncidentTitle('Please Enter Report Name');
      return false;
    }

    form_data.append('report_name', values.report_name);
    form_data.append('report_type', values.report_type);

    if (date2) {
      form_data.append("from_added_date", date2);
    }
    if (date3) {
      form_data.append("to_added_date", date3);
    }
    if (date) {
      form_data.append("from_incident_date", date);
    }
    if (date1) {
      form_data.append("to_incident_date", date1);
    }
    if (date4) {
      form_data.append("from_updated_date", date4);
    }
    if (date5) {
      form_data.append("to_updated_date", date5);
    }
    if (values.emirate_id) {
      form_data.append("emirate_id", values.emirate_id);
    }
    if (values.agent_id) {
      form_data.append("agent_id", values.agent_id);
    }
    if (values.phone_number) {
      form_data.append("phone_number", values.phone_number);
    }
    if (values.area_name) {
      form_data.append("area_name", values.area_name);
    }
    if (values.person_id) {
      form_data.append("person_id", values.person_id);
    }
    if (values.artifact_id) {
      form_data.append("artifact_id", values.artifact_id);
    }

    console.log(values)
    setLoading(true);
    axios.post(url + 'incident-report', form_data, {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    })
      .then(res => {
        if (res.data.ResponseStatus === '204') {
          setLoading(false);
          setValues([]);
          form.current.reset();
          toast.error(res.data.ResponseMessage);
        } else {
          setLoading(true);
          toast.success("File Generated Successfully!");
          navigate('/incident-report-list');
        }

      })
      .catch(error => {
        setLoading(false);
        // toast.error("Select any filter, Report name and Report type for Generate Report");
        if (error.response.data.ResponseData.error.report_name) {
          SetIncidentTitle(error.response.data.ResponseData.error.report_name[0]);
        }
        if (error.response.data.ResponseData.error.report_type) {
          setReportType(error.response.data.ResponseData.error.report_type[0]);
        }
      })
  }

  const [pageCount, setPageCount] = useState(0);
  const [serialnum, setserialnum] = useState(1);
  const [incientdate, setincidentDate] = useState();
  const [updatedate, setupdateDate] = useState();
  const [agentid, setagent] = useState();
  const [personid, setInformat] = useState();
  const [phonenumber, setNumber] = useState();
  const [artifactid, setArtifact] = useState();
  const [areaname, setArea] = useState();
  const [emirateid, setEmirate] = useState();
  const [incidentreport, setincidentReportList] = useState();
  const [toincientdate, setToIncident] = useState();
  const [fromadded, setFromAdded] = useState();
  const [toadded, setToAdded] = useState();
  const [toupdatedate, setToUpdate] = useState();




  const incidentReportData = (page) => {
    let pagenum = page + 1;
    let para = '';
    if (fromadded) {
      let fordate = format(parseISO(fromadded), 'yyyy-LL-dd');
      para += '&from_added_date=' + fordate;
    }
    if (toadded) {
      let fordate = format(parseISO(toadded), 'yyyy-LL-dd');
      para += '&to_added_date=' + fordate;
    }

    if (updatedate) {
      let fordate = format(parseISO(updatedate), 'yyyy-LL-dd');
      para += '&from_updated_date=' + fordate;
    }
    if (toupdatedate) {
      let fordate = format(parseISO(toupdatedate), 'yyyy-LL-dd');
      para += '&to_updated_date=' + fordate;
    }

    if (incientdate) {
      let fordate = format(parseISO(incientdate), 'yyyy-LL-dd');
      para += '&from_incident_date=' + fordate;
    }
    if (toincientdate) {
      let fordate = format(parseISO(toincientdate), 'yyyy-LL-dd');
      para += '&to_incident_date=' + fordate;
    }

    if (personid) {
      para += '&person_id=' + personid;
    }
    if (phonenumber) {
      para += '&phone_number=' + phonenumber;
    }
    if (artifactid) {
      para += '&artifact_id=' + artifactid;
    }
    if (areaname) {
      para += '&area_name=' + areaname;
    }
    if (emirateid) {
      para += '&emirate_id=' + emirateid;
    }

    if (agentid) {
      para += '&agent_id=' + agentid;
    }



    axios.get(url + 'preview-incident-report?page=' + pagenum + para, {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setincidentReportList(response.data.ResponseData.data.incidentData.data);
      // settotalCount(response?.data?.ResponseData?.data?.incidentData?.total);
      setPageCount(response?.data?.ResponseData?.data?.incidentData?.total / 10);
      setShow(true)
    }).catch(error => {
    });
    axios.get(url + 'module-configs', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }

    }).then(response => {
      setMenuConfig(response.data.ResponseData.data.moduleConfigList);
    }).catch(error => {
    });
  }
  const searchData = () => {
    incidentReportData(0)
  }

  const handlePageClick = (event) => {
    setserialnum(event.selected * 10 + 1);
    incidentReportData(event.selected);
  };


  const resetData = () => {
    setFromAdded('');
    setToAdded('');
    setupdateDate('');
    setToUpdate('');
    setincidentDate('');
    setToIncident('');
    setEmirate('');
    setArea('');
    setArtifact('');
    setNumber('');
    setInformat('');
    setagent('');
    fetchData(0)
  }

  return (
    <div className="wrapper">
      <Sidebar />
      <div id="inner-content" className="none">
        <section id="management" className="content">
          <Topbar pagename={pagename} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="content-block incident-reports b-filter">
                  <div className="row">
                    <div className="col-7 col-xs-9 incident-reports-head">
                      <h2 className="title">{menuconfig.section_incident_reports_generate}</h2><p>(Select Filters to Generate Report)</p>
                    </div>
                    <div className="col-5 col-xs-3">
                      <div className="add-buttons">
                        <Link to="/incident-report-list" className="button large-button button-color-red"><i className="icon eye-icon icon-left"></i> View previous reports</Link>
                      </div>
                    </div>
                    <div className="col-12 col-xl-12 col-lg-12 col-xs-12">
                      <div className="report-filters-block">
                        <div className="row">
                          <div className="col-12 col-xs-12">
                            <p>Filter by </p>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="filter-date">
                              <Flatpickr
                                id="from_added_date"
                                data-enable-time
                                value={fromadded}
                                name="from_added_date"
                                placeholder="From Added Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate2(dateStr);
                                  setFromAdded(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>
                              {/* <div className="error">{fromadded}</div> */}
                            </div>
                            <div className="filter-date">
                              <Flatpickr
                                id="to_added_date"
                                data-enable-time
                                value={toadded}
                                name="to_added_date"
                                placeholder="To Added Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate3(dateStr);
                                  setToAdded(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>
                              {/* <div className="error">{toadded}</div> */}
                            </div>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="filter-date">
                              <Flatpickr
                                id="from_updated_date"
                                data-enable-time
                                value={updatedate}
                                name="from_updated_date"
                                placeholder="From update Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate4(dateStr);
                                  setupdateDate(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>

                            </div>
                            <div className="filter-date">
                              <Flatpickr
                                id="to_updated_date"
                                data-enable-time
                                value={toupdatedate}
                                name="to_updated_date"
                                placeholder="To Update Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate5(dateStr);
                                  setToUpdate(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>
                            </div>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="filter-date">
                              <Flatpickr
                                id="from_incident_date"
                                data-enable-time
                                value={incientdate}
                                name="from_incident_date"
                                placeholder="From incident Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate(dateStr);
                                  setincidentDate(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>
                              {/* <div className="error">{updatedate}</div> */}
                            </div>
                            <div className="filter-date">
                              <Flatpickr
                                id="to_incident_date"
                                data-enable-time
                                value={toincientdate}
                                name="to_incident_date"
                                placeholder="To incident Date"
                                options={{
                                  allowInput: true
                                }}
                                onChange={(selectedDates, dateStr, instance) => {
                                  const firstDate = selectedDates[0];
                                  console.log({ firstDate, dateStr });
                                  console.log(dateStr);
                                  setDate1(dateStr);
                                  setToIncident(dateStr);
                                }} />
                              <i className="icon chevron-down-icon"></i>
                              {/* <div className="error">{toupdatedate}</div> */}
                            </div>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="sel select-filter sel-six">
                              <select name="agent_id" id="select-agent" value={agentid}
                                onChange={(e) => { handleInputChange(e); setagent(e.target.value); }}>
                                <option value="" >Added by Agent</option>
                                {agentlist.length > 0 && agentlist.map((age, i) => (
                                  <>
                                    <option value={age.id} >{age.first_name}</option>
                                  </>
                                ))
                                }
                              </select>
                            </div>
                            <div className="sel select-filter sel-five">
                              <select name="person_id" id="select-informant" value={personid}
                                onChange={(e) => { handleInputChange(e); setInformat(e.target.value); }}>
                                <option value="" >Informant</option>
                                {personList && personList.map((person, i) => (
                                  <>
                                    <option value={person.id} >{person.person_name}</option>
                                  </>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="sel select-filter sel-four">
                              <select name="phone_number" id="select-phone" value={phonenumber}
                                onChange={(e) => { handleInputChange(e); setNumber(e.target.value); }}>
                                <option value="" >Phone Number</option>
                                {incidentReportList && incidentReportList.map((primeNo, i) => (
                                  <>
                                    <option value={primeNo.id} >{primeNo.phone_number}</option>
                                  </>
                                ))}
                              </select>
                            </div>
                            <div className="sel select-filter sel-three">
                              <select name="artifact_id" id="select-artifact" value={artifactid}
                                onChange={(e) => { handleInputChange(e); setArtifact(e.target.value); }}>
                                <option value="" >Artifact Name</option>
                                {artifactList && artifactList.map((artifact, i) => (
                                  <>
                                    <option value={artifact.artifact_name} >{artifact.artifact_name}</option>
                                  </>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-2 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="sel select-filter sel-two">
                              <select name="area_name" id="select-area" value={areaname}
                                onChange={(e) => { handleInputChange(e); setArea(e.target.value); }}>
                                <option value="" >Area Name</option>
                                {locationList && locationList.map((location, i) => (
                                  <>
                                    <option value={location.area_name} >{location.area_name}</option>
                                  </>
                                ))}
                              </select>
                            </div>
                            <div className="sel select-filter sel-one">
                              <select name="emirate_id" id="select-city" value={emirateid} onChange={(e) => { handleInputChange(e); setEmirate(e.target.value); }}>
                                <option value="" >City/Emirate</option>
                                {emirateList && emirateList.map((emi, i) => (
                                  <>
                                    <option value={emi.id} >{emi.emirate_name}</option>
                                  </>
                                ))
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-12 col-lg-12 col-xs-12">
                      <div className="search-block">
                        <div className="row">
                          <div className="col-4 col-xs-12">
                            <div className="search-report" id="search-report">
                              <div className="sel select-filter sel-one filterreporttype">
                                <span className="asterisk_input">*</span>
                                <select name="report_type" id="report_type" onChange={(e) => { handleInputChange(e); setReportType(''); }}>
                                  <option value="" >Report Type</option>
                                  <option value='pdf' >PDF</option>
                                  <option value='csv' >CSV</option>
                                </select>
                                <div className="error">{reporttype}</div>
                              </div>
                            </div>



                          </div>
                          <div className="col-8 col-xs-12">
                            <div className="search-report reportnamesearch">
                              <span className="asterisk_input">*</span>
                              <input id="report-name" name='report_name' className="search-box" placeholder="Enter Expected Report Name" defaultValue="" onChange={(e) => { handleInputChange(e); SetIncidentTitle(''); }} />
                              <div className="error">{reportname}</div>
                              <div className="filter-preview">
                                <button className="button middle-button button-color modal-button" type="button" onClick={searchData} ><i className="icon eye-icon icon-left"></i> Preview Data</button>
                                <button className="button middle-button button-color-outline" type="button" onClick={resetData}><i className="icon cross-circle-icon icon-left"></i> Reset</button>
                              </div>

                            </div>


                          </div>
                          {/* <div className="col-4 col-xs-12">
                            <div className="generate-buttons">
                              <button className="button large-button button-color-blue" onClick={downloadFile}>Generate & download report</button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <Modal show={show} onHide={handleClose}>
                        <div className="modal-open">
                          <div className="report-preview">
                            <div className="modal-head">
                              <h2 className="subtitle">{menuconfig.section_incident_reports_preview}</h2>
                              <Link className="modal-close" to='/incidentreport' onClick={handleClose}>&times;</Link>
                            </div>
                            <div className="master-items scrollable scrollable-div viewincidentreport">
                              <div className="modal-inner-content" id="report-preview">
                                <div className="grid grid-nine">

                                  {
                                    incidentreport && incidentreport.length > 0 ? incidentreport && incidentreport.map((inc, i) => (
                                      <>
                                        {i === 0 &&
                                          <>

                                            <div className="ch1 ch">Sr No</div>
                                            <div className="ch2 ch"><span>Incident </span>ID</div>
                                            <div className="ch3 ch">Location</div>
                                            <div className="ch4 ch">Phone<span> Number</span></div>
                                            <div className="ch5 ch">Title</div>
                                            <div className="ch6 ch">Incident Date</div>
                                            <div className="head-line"></div>
                                          </>
                                        }

                                        <div className="grid-line"></div>
                                        <div className="cb cb1">{serialnum + i}</div>
                                        <div className="cb cb2">{inc?.auto_incident_id}</div>
                                        <div className="cb cb3">{inc?.emirate_data?.emirate_name}</div>
                                        <div className="cb cb4">{inc?.phone_number}</div>
                                        <div className="cb cb5">{inc?.incident_title}</div>
                                        <div className="cb cb6">{inc?.incident_date}</div>


                                      </>
                                    )) : <><div className="grid-line"></div>
                                      <div className="ch1 ch">Sr No</div>
                                      <div className="ch2 ch"><span>Incident </span>ID</div>
                                      <div className="ch3 ch">Location</div>
                                      <div className="ch4 ch">Phone<span> Number</span></div>
                                      <div className="ch5 ch">Title</div>
                                      <div className="ch6 ch last-cell"><span>Incident </span>Date Time</div>
                                      <div className="head-line"></div>
                                      <div className="cb cb1"><p>No Record Found</p></div></>
                                  }

                                </div>
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  pageCount={pageCount}
                                  //previousLabel="< previous"
                                  containerClassName={'pagination'}
                                  activeClassName={'active'}
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div className="col-12 col-xl-12 col-lg-12 col-xs-12">
                      <div className="generate-buttons">
                        <button className="button large-button button-color-blue" onClick={downloadFile} disabled={loading}>Generate Report</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  );
}

export default Incidentreport;