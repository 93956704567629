import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, removeUserSession } from '../../utils/Common';
import { getUrl } from '../../utils/Urlpath';
import { Modal } from 'react-bootstrap';
import Moment from 'moment';
import { AreaChart, Area, YAxis, XAxis, Tooltip, Legend } from 'recharts';
import { RangeSlider } from '../../Common/RangeSlider';
import Marquee from "react-text-marquee";
import LeafMapView from './LeafMapView';
import Progress from './Progress';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const toYearLabel = year => year;

const currentYear = new Date().getFullYear();

const previousYear = []
for (let i = currentYear; i > (currentYear - 4); i--) {
  previousYear.push(i)
}

function Dashboard() {
  const currentYear = new Date().getFullYear();
  const url = getUrl();
  const [, setUserImage] = useState('');
  const [emiratesList, setEmiratesList] = useState([]);
  const [incidentDetails, setIncidentDetails] = useState([]);
  const [artifactAlert, setArtifactAlert] = useState([]);
  const [, setAbcdFeed] = useState();
  const [count, setCount] = useState([]);
  const [incidentYear, setIncidentYear] = useState(currentYear);
  const [abcdYear, setAbcdYear] = useState(currentYear);
  const [artifactYear, setArtifactYear] = useState(currentYear);
  const [incidentDetailYear, setIncidentDetailYear] = useState(currentYear);
  const [incidentChartYear, setIncidentChartYear] = useState(currentYear);
  const [trendList, setTrendList] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [marqueData, setMarqueData] = useState([]);
  const [show, setShow] = useState(false);
  const [heatmapList, setHeatmapList] = useState([])
  const [yearSelectedRange, setYearSelectedRange] = useState({ start: currentYear });

  const timeDisplay = document.getElementsByClassName('timeDisplay');
  const timeZones = ["Asia/Dubai", "Asia/Karachi", "Asia/Kolkata"];

  const [serialnum, setserialnum] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const handlePageClick = (event) => {
    setserialnum(event.selected * 10 + 1);
    searchIncidentData(event.selected);
  };

  useEffect(() => {
    for (let i = 0; i < timeDisplay.length; i++) {
      setInterval(function tick() {
        let time = new Date()
        time = time.toLocaleTimeString("en-US", {
          timeZone: timeZones[i],
          timeStyle: 'short'
        })
        timeDisplay[i].innerText = time
      }, 1000);
    }
  });

  let [siteconfig, setsiteconfig] = useState([]);
  var sitName = localStorage.getItem("site_name")

  useEffect(() => {
    axios.get(url + 'app-site-config', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setsiteconfig(response?.data?.ResponseData?.data?.siteConfigList?.site_logo);
    }).catch(error => {

    });
    // This will run when the page first loads and whenever the title changes
    document.title = sitName + ' - Dashboard';
  }, [siteconfig, url, sitName]);

  const [primePhoneNumberList, setPrimePhoneNumberList] = useState([]);
  const [personList, setPersonList] = useState([]);
  const [artifactList, setArtifactList] = useState([]);
  const [heatmap, setHeatmap] = useState([]);

  let [menuconfig, setMenuConfig] = useState([]);


  // const basicFilterData = () => {
  //   axios.get(url + 'basic-filter-details', {
  //     headers: {
  //       'Authorization': 'Bearer ' + getToken()
  //     }
  //   }).then(response => {
  //     setPrimePhoneNumberList(response.data.ResponseData.data.primePhoneNumberList)
  //     setPersonList(response.data.ResponseData.data.personList)
  //     setArtifactList(response.data.ResponseData.data.ArtifactList)
  //   }).catch(error => {
  //   });
  // }

  useEffect(() => {
    const basicFilterData = () => {
      axios.get(url + 'basic-filter-details', {
        headers: {
          'Authorization': 'Bearer ' + getToken()
        }
      }).then(response => {
        setPrimePhoneNumberList(response.data.ResponseData.data.primePhoneNumberList)
        setPersonList(response.data.ResponseData.data.personList)
        setArtifactList(response.data.ResponseData.data.ArtifactList)
      }).catch(error => {

      });
    }
    // This will run when the page first loads and whenever the title changes
    basicFilterData();
  }, [url]);

  const queryData = useLocation().search;
  var phoneNumber = new URLSearchParams(queryData).get('p');
  phoneNumber = (phoneNumber !== null && phoneNumber !== '') ? phoneNumber : []

  var countryNumber = new URLSearchParams(queryData).get('c');
  countryNumber = (countryNumber !== null && countryNumber !== '') ? countryNumber : []

  var informentNumber = new URLSearchParams(queryData).get('i');
  informentNumber = (informentNumber !== null && informentNumber !== '') ? informentNumber : []

  var artifactsNumber = new URLSearchParams(queryData).get('a');
  artifactsNumber = (artifactsNumber !== null && artifactsNumber !== '') ? artifactsNumber : []

  var yearNumber = new URLSearchParams(queryData).get('y');
  yearNumber = (yearNumber !== null && yearNumber !== '') ? yearNumber : 2022;

  // var defaultSelYear = (yearNumber != null && yearNumber != '') ? yearNumber : 2022;

  const [countryCode, setCountryCode] = useState(countryNumber);
  const [phoneNo, setPhoneNo] = useState(phoneNumber);
  const [informant, setInformant] = useState(informentNumber);
  const [artifact, setArtifact] = useState(artifactsNumber);
  const [yearChange, setYearChange] = useState(yearNumber);


  const onPrimePhoneChange = (e) => {
    var org_val = e.target.value;
    var split_value = org_val.split('/');
    setCountryCode(split_value[0]);
    setPhoneNo(split_value[1]);
    navigate({
      search: `?p=${split_value[1]}&c=${split_value[0]}&i=${informant}&a=${artifact}&y=${yearSelectedRange.start}`,
    });
    window.location.reload();
  };

  const onInformantChange = (e) => {
    setInformant(e.target.value);
    navigate({
      search: `?p=${phoneNo}&c=${countryCode}&i=${e.target.value}&a=${artifact}&y=${yearSelectedRange.start}`,
    });
    window.location.reload();
  };

  const onArtefactsChange = (e) => {
    setArtifact(e.target.value);
    navigate({
      search: `?p=${phoneNo}&c=${countryCode}&i=${informant}&a=${e.target.value}&y=${yearChange}`,
    });
    window.location.reload();
  };

  const onSliderChange = (e) => {
    if (e.start !== yearSelectedRange.start) {
      setYearSelectedRange(e)
      setYearChange(yearSelectedRange.start)
      navigate({
        search: `?p=${phoneNo}&c=${countryCode}&i=${informant}&a=${artifact}&y=${e.start}`,
      });
      window.location.reload();
    }

  };

  const heatmapData = () => {
    axios.get(url + `heatmap-data?phone_number=${phoneNo}&incident_country_code=${countryCode}&person_id=${informant}&artifact_id=${artifact}&slider_year=${yearChange}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setHeatmap(response.data.ResponseData.data)
      setHeatmapList(response.data.ResponseData.data.heatmapData)
    }).catch(error => {
    });
  }

  const navigate = useNavigate();

  useEffect(() => {

    heatmapData();

    if ((phoneNo !== '' && phoneNo !== 'null') || (countryCode !== '' && countryCode !== 'null') || (informant !== '' && informant !== 'null') || (artifact !== '' && artifact !== 'null') || (yearChange !== '' && yearChange !== 'null')) {

      navigate({
        search: `?p=${phoneNo}&c=${countryCode}&i=${informant}&a=${artifact}&y=${yearChange}`,
      });
      setYearSelectedRange({ start: yearNumber })
    }
  }, [phoneNo, countryCode, informant, artifact, yearChange]);

  useEffect(() => {
    axios.get(url + 'marque-data', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setMarqueData(response.data.ResponseData.data.marqueData)
    }).catch(error => {
    });


  }, [url]);

  const fetchData = () => {
    axios.get(url + 'get-user-profile', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setUserImage(response.data.ResponseData.data.profile_image);
    }).catch(error => {
    });
    axios.get(url + 'module-configs', {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }

    }).then(response => {
      setMenuConfig(response.data.ResponseData.data.moduleConfigList);
    }).catch(error => {
    });
  }
  // const [cityData, setCityData] = useState([]);

  // const onCityChange = (e) => {
  //   setCityData("emirate_id=" + e.target.value)
  // };

  const [cityData, setCityData] = useState([]);

  const uaeCityName = (data) => {
    var emirateData;
    if (data === 'Abu Dhabi') {
      emirateData = 1;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Dubai') {
      emirateData = 2;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Sharjah') {
      emirateData = 3;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Ajman') {
      emirateData = 4;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Ras Al-Khaimah') {
      emirateData = 5;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Umm Al Quwain') {
      emirateData = 6;
      setCityData("emirate_id=" + emirateData)
    } else if (data === 'Fujairah') {
      emirateData = 7;
      setCityData("emirate_id=" + emirateData)
    } else {
      setCityData()
    }
  }

  const dashboardData = () => {
    axios.get(url + `master-dashboard-common-list?emirate_year=${incidentYear}&artifact_year=${artifactYear}&incident_year=${incidentDetailYear}&trend_chart_year=${incidentChartYear}&${cityData}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setCount(response.data.ResponseData.data);
      setEmiratesList(response.data.ResponseData.data.emiratesList);
      setIncidentDetails(response?.data?.ResponseData?.data?.incidentDetails);
      setArtifactAlert(response.data.ResponseData.data.artifactAlert);
      setAbcdFeed(response.data.ResponseData.data.abcdFeed);
      setTrendList(response.data.ResponseData.data.trendList);
    }).catch(error => {
    });
  }

  useEffect(() => {
    fetchData();
    dashboardData();

  }, [incidentYear, artifactYear, incidentDetailYear, incidentChartYear, cityData]);

  const [abcData, setabcData] = useState([]);
  const [epiData, setepiData] = useState([]);

  useEffect(() => {
    axios.get(url + `abcd-rss-feed?year=${abcdYear}`, {
      headers: {
        'Authorization': 'Bearer ' + getToken()
      }
    }).then(response => {
      setabcData(response.data.abc)
      setepiData(response.data.epi)
    }).catch(error => {
    });

  }, [abcdYear, url]);


  const onIncidentyearChange = (e) => {
    setIncidentYear(e.target.value);
  };

  const onAbcyearChange = (e) => {
    setAbcdYear(e.target.value);
  };

  const onArtifactyearChange = (e) => {
    setArtifactYear(e.target.value);
  };

  const onIncidentDetailyearChange = (e) => {
    setIncidentDetailYear(e.target.value);
  };

  const onIncidentChartChange = (e) => {
    setIncidentChartYear(e.target.value);
  };

  const setSearchData = (e) => {
    setSearch(e.target.value);
  };

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    searchIncidentData(0)
  }

  const searchIncidentData = (page) => {
    let pagenum = page + 1;
    axios.get(url + 'search-incident-data?page=' + pagenum, {
      params: { search },
      headers: { Authorization: 'Bearer ' + getToken() }
    }).then(response => {
      setSearchedData(response.data.ResponseData.data.previewData.data)
      settotalCount(response.data.ResponseData.data.previewData.total);
      setPageCount(response.data.ResponseData.data.previewData.total / 10);
      setShow(true);
    }).catch(error => {
    });

  }

  const chartData = trendList;

  const handleLogin = () => {
    removeUserSession();
    navigate('/');
    window.location.reload();
  }


  return (
    <div className="wrapper master">
      <header id="master-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 col-lg-3 col-xs-9">
              <div className="main-logo dashlogo">
                <Link to="/dashboard"><img src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1224184972.1715212800&semt=sph" className="img-responsive" alt="Logo" /></Link>
              </div>
            </div>
            <div className="col-4 col-lg-4 col-xs-12 last-xs">
              <h1 className="title">{menuconfig.section_dashboard_details}</h1>
            </div>
            <div id="clocks" className="col-4 col-lg-5 col-xs-3">
              <div className="clocks-user">
                <a href="/addquick" >
                  <i className="icon plus-circle-icon add-incident-icon" title='Add Quick Incident'></i>
                </a>

                <div className="time-container">
                  <div className="time-city">UAE</div>
                  <div id="rootUAE" className="timeDisplay">2:49 PM</div>
                </div>
                <div className="time-container">
                  <div className="time-city">Pakistan</div>
                  <div id="rootPakistan" className="timeDisplay">3:49 PM</div>
                </div>
                <div className="time-container">
                  <div className="time-city">India</div>
                  <div id="rootIndia" className="timeDisplay">4:19 PM</div>
                </div>
                <div className="user-profile">
                  <div className="user-picture">
                    <img src={localStorage.getItem("profile_image")} className="img-responsive" alt="profile" />
                  </div>
                  <div className="user-data">
                    <Link to="/incidents" class="user-account"><i class="icon settings3-icon"></i>Go to Admin</Link>
                    {/* <Link to="/userprofile" className="user-account"><i className="icon profile-icon icon-left"></i> Profile</Link> */}
                    <a className="user-account" href={() => false} onClick={handleLogin}><i className="icon logout-icon"></i><span>Logout</span></a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xs-12">
              <div className="master-incident-report">
                <div className="alert-title">INCIDENT REPORT</div>
                <div className="alert-txt">
                  <Marquee
                    text={marqueData.map(data => (
                      <span title="">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{ color: 'red' }}>{data.incident_time}</span> – {data.emirate_data.emirate_name} – {data.incident_title}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        &#x25C7;
                      </span>
                    ))}
                    hoverToStop={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="master-content" className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="master-box">
                <div className="master-section-head">
                  <h2 className="title">SEARCH</h2>
                  <div className="">
                    {/* <div className="sel master-select">
                      <select name="select-substance" id="select-substance">
                        <option value="" >Substance</option>
                        <option value="substance1">Substance 1</option>
                        <option value="substance2">Substance 2</option>
                        <option value="substance3">Substance 3</option>
                      </select>
                    </div> */}
                  </div>
                </div>
                <div className="master-section-content">
                  <div className="master-search">
                    <form>
                      <input id="master-search" className="search-box" onChange={(e) => setSearchData(e)} placeholder="Enter (Phone, Substance, Location)" />
                      <button className="no-button" type="submit" onClick={handleShow} ><i className="icon chevron-right-icon"></i></button>
                    </form>
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <div className="modal-open view-incident scrollable scrollable-div">
                    <div className="incident">
                      <div className="modal-head">
                        <h2 className="subtitle">Incident (<span className="total-text">Total {totalCount}</span>)</h2>
                        <a className="modal-close" href={() => false} onClick={handleClose}>&times;</a>
                      </div>
                    </div>
                    <div className="modal-inner-content search-data" id="report-preview">
                      <div className="grid grid-nine">


                        {searchedData && searchedData.length > 0 ? searchedData && searchedData.map((data, i) => (
                          <>

                            {i === 0 &&
                              <>

                                <div className="ch1 ch">Sr No</div>
                                <div className="ch2 ch">{menuconfig.section_dashboard_details_filter_incident_title}</div>
                                <div className="ch3 ch">{menuconfig.section_dashboard_details_filter_phone}</div>
                                <div className="ch4 ch">{menuconfig.section_dashboard_details_filter_emirate}</div>
                                <div className="ch5 ch">{menuconfig.section_dashboard_details_filter_artifact}</div>
                                <div className="ch6 ch">{menuconfig.section_dashboard_details_filter_date_time}</div>
                                {/* <div className="ch2 ch">Incident Title</div>
                                <div className="ch3 ch">Phone</div>
                                <div className="ch4 ch">Emirate</div>
                                <div className="ch5 ch">Artifact</div>
                                <div className="ch6 ch"><span>Incident </span>Date Time</div> */}

                                <div className="head-line"></div>
                              </>
                            }

                            <div className="cb cb1">{serialnum + i}</div>
                            {/* <div className="cb cb1">{data?.incident_title}</div> */} 
                            {data?.location_data?.lat != null ? (

                              <div className="cb cb2"><a href={'/dashboarddetail/' + data.id} className="no-button" >{data?.incident_title}</a></div>
                            ) : (
                              <div className="cb cb2">{data?.incident_title}</div>
                            )
                            }
                            <div className="cb cb3">{data?.phone_number ? data?.phone_number : '-'}</div>
                            <div className="cb cb4">{data?.emirate_data?.emirate_name ? data?.emirate_data?.emirate_name : '-'}</div>
                            <div className="cb cb5">{data?.artifact_data?.artifact_name ? data?.artifact_data?.artifact_name : '-'}</div>
                            <div className="cb cb6">{Moment(data.incident_date).format('MMMM Do YYYY')}</div>
                            <div className="grid-line"></div>
                          </>
                        )) : <><div className="grid-line"></div>
                          <div className="ch1 ch">Sr No</div>
                          <div className="ch2 ch">{menuconfig.section_dashboard_details_filter_incident_title}</div>
                          <div className="ch3 ch">{menuconfig.section_dashboard_details_filter_phone}</div>
                          <div className="ch4 ch">{menuconfig.section_dashboard_details_filter_emirate}</div>
                          <div className="ch5 ch">{menuconfig.section_dashboard_details_filter_artifact}</div>
                          <div className="ch6 ch">{menuconfig.section_dashboard_details_filter_date_time}</div>

                          <div className="head-line"></div>
                          <div className="cb cb1"><p>No Record Found</p></div></>
                        }

                      </div>

                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        //previousLabel="< previous"
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        renderOnZeroPageCount={null}
                      />

                    </div>
                  </div>
                </Modal>
                <div className="master-section-head">
                  <h2 className="subtitle">{menuconfig.section_dashboard_details_incidents} (<span className="total-text">Total {count.incidentsLabelCount}</span>)</h2>
                  <div className="">
                    <div className="sel master-select">
                      <select name="select-year" id="select-year" onChange={onIncidentyearChange}>
                        {previousYear && previousYear.map(data => (
                          <option value={data}>{data}</option>
                        ))
                        }
                        {/* <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <Progress progressData={emiratesList} />
                <div className="master-section-head">
                  <h2 className="subtitle">{menuconfig.section_dashboard_details_rashid_data}</h2>
                  <div className="">
                    <div className="sel master-select">
                      <select name="select-year" id="select-year" onChange={onAbcyearChange}>
                        {previousYear && previousYear.map(data => (
                          <option value={data}>{data}</option>
                        ))
                        }
                        {/* <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="master-section-content abc-data">
                  <div className="master-items-header">
                    <div className="master-item-name">{menuconfig.section_dashboard_details_rashid_data_abc}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Instagram</div>
                    <div className="master-item-data">{abcData === undefined ? 0 : abcData?.instagram}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Twitter</div>
                    <div className="master-item-data">{abcData === undefined ? 0 : abcData?.twitter}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Other</div>
                    <div className="master-item-data">{abcData === undefined ? 0 : abcData?.other}</div>
                  </div>
                </div>
                <div className="master-section-content abc-data">
                  <div className="master-items-header">
                    <div className="master-item-name">{menuconfig.section_dashboard_details_rashid_data_epi}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Instagram</div>
                    <div className="master-item-data">{epiData === undefined ? 0 : epiData?.instagram}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Twitter</div>
                    <div className="master-item-data">{epiData === undefined ? 0 : epiData?.twitter}</div>
                  </div>
                  <div className="master-item">
                    <div className="master-item-name">Other</div>
                    <div className="master-item-data">{epiData === undefined ? 0 : epiData?.other}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-12 first-lg col-md-12 first-md col-sm-12 first-md col-xs-12 first-xs">
              <div className="master-map-filters">
                <h2 className="title">MAP FILTER OPTIONS</h2>
                <div className="sel master-map-select">
                  <select name="master-select-phone" id="master-select-phone" onChange={onPrimePhoneChange}>
                    {/* <option value="/">Phone</option> */}
                    <option value="/">{menuconfig.section_dashboard_details_filter_by_phone}</option>
                    {primePhoneNumberList && primePhoneNumberList.map(data => (
                      <option value={data.incident_country_code + '/' + data.phone_number} selected={data.phone_number === phoneNumber ? true : false}>{data.phone_number}</option>
                    ))
                    }

                  </select>
                </div>
                <div className="sel master-map-select">
                  <select name="master-select-informant" id="master-select-informant" onChange={onInformantChange}>
                    {/* <option value="" >Informant</option> */}
                    <option value="" >{menuconfig.section_dashboard_details_filter_by_informant}</option>
                    {personList && personList.map(data => (
                      <option value={data.id} selected={data.id === informentNumber ? true : false}>{data.person_name}</option>
                    ))
                    }
                  </select>
                </div>
                <div className="sel master-map-select">
                  <select name="master-select-artefact" id="master-select-artefact" onChange={onArtefactsChange}>
                    {/* <option value="" >Artefacts</option> */}
                    <option value="" >{menuconfig.section_dashboard_details_filter_by_artifact}</option>
                    {artifactList && artifactList.map(data => (
                      <option value={data.artifact_name} selected={data.artifact_name === artifactsNumber ? true : false}>{data.artifact_name}</option>
                    ))
                    }
                  </select>
                </div>
              </div>
              <div className="master-map-zone">
                <div className="master-map-title">
                  <h2 className="title">Total Incidents: {heatmap.totalIncidents ? heatmap.totalIncidents : 0}</h2>
                </div>
                {/* Map view start */}

                <LeafMapView data1={heatmapList} uaeCityName={uaeCityName} />
                {/* Map view end */}
                <div className="yearrangeslider">
                  <RangeSlider
                    min={currentYear - 3}
                    max={currentYear}
                    valuePerStep={1}
                    labelMarkStep={1}
                    value={yearNumber}
                    selectedRange={yearSelectedRange}
                    onChange={(e) => onSliderChange(e)}
                    formatLabel={toYearLabel}
                    className='range-slider'
                  />
                </div>
              </div>
            </div>
            <div className="col-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="master-box">
                <div className="master-section-head">
                  <h2 className="subtitle">{menuconfig.section_dashboard_details_artifact_alerts} (<span className="total-text">Total {count.artifactAlertLabel}</span>)</h2>
                  <div className="">
                    <div className="sel master-select">
                      <select name="select-year" id="select-year" onChange={onArtifactyearChange}>
                        {previousYear && previousYear.map(data => (
                          <option value={data}>{data}</option>
                        ))
                        }
                        {/* <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="master-section-content">
                  <div className="master-items scrollable scrollable-div">
                    {artifactAlert && artifactAlert.map(data => (
                      <div className="master-item">
                        <div className="master-item-name">{data.artifact_name}</div>
                        <div className="master-item-data">{data.artifactCount}</div>
                      </div>
                    ))
                    }
                  </div>
                </div>
                <div className="master-section-head">
                  <h2 className="subtitle">{menuconfig.section_dashboard_details_incident_details} (<span className="total-text">Total {count.incidentDetailsLabelCount}</span>)</h2>
                  <div className="">
                    <div className="sel master-select">
                      <select name="select-year" id="select-year" onChange={onIncidentDetailyearChange}>
                        {previousYear && previousYear.map(data => (
                          <option value={data}>{data}</option>
                        ))
                        }
                        {/* <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="master-section-content">
                  <div className="master-items-header">
                    <div className="master-item-name">{menuconfig.section_dashboard_details_incident_details_contact}</div>
                    <div className="master-item-data">{menuconfig.section_dashboard_details_incident_details_drop}</div>
                    <div className="master-item-data">{menuconfig.section_dashboard_details_incident_details_friend}</div>
                  </div>
                  <div className="master-items scrollable scrollable-div">

                    {incidentDetails && incidentDetails.map(data => (
                      <div className="master-item">
                        <div className="master-item-name">{data?.main_number}</div>
                        <div className="master-item-data">{data?.drops}</div>
                        <div className="master-item-data">{data?.friends}</div>
                      </div>
                    ))
                    }
                  </div>
                </div>
                <div className="master-section-head">
                  <h2 className="subtitle">{menuconfig.section_dashboard_details_uae_incidents}
                  </h2>
                  <div className="">
                    <div className="sel master-select">
                      <select name="select-year" id="select-year" onChange={onIncidentChartChange}>
                        {previousYear && previousYear.map(data => (
                          <option value={data}>{data}</option>
                        ))
                        }
                        {/* <option value="2022" >2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="master-section-content">
                  <div className="line-chart">
                    <AreaChart width={400} height={250} data={chartData}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorUv" >
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" >
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="month" />
                      <YAxis />
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <Tooltip />
                      <Legend />
                      <Area type="monotone" dataKey="Fujairah" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                      <Area type="monotone" dataKey="Umm Al Quwain" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                      <Area type="monotone" dataKey="Ras Al-Khaimah" stroke="#8A5659" fillOpacity={1} fill="url(#colorPv)" />
                      <Area type="monotone" dataKey="Ajman" stroke="#F13B3B" fillOpacity={1} fill="url(#colorPv)" />
                      <Area type="monotone" dataKey="Sharjah" stroke="#7BDDD8" fillOpacity={1} fill="url(#colorPv)" />
                      <Area type="monotone" dataKey="Dubai" stroke="#B568CB" fillOpacity={1} fill="url(#colorPv)" />
                      <Area type="monotone" dataKey="Abu Dhabi" stroke="#CA4DA7" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;