import React, { useState, useEffect } from 'react';
import { CircleMarker, MapContainer, Marker, useMap, ZoomControl, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-uae-heatmap";
import "leaflet.heat";
import Control from "react-leaflet-custom-control";
import { uaeJson } from "./UaeData";
import icon from "./constants";
import { useNavigate } from 'react-router-dom';

var ChoroplethLoaded = false;
var heatData = false;

function Markers({ data }) {
    const map = useMap();
    const markerArr = [];
    return (
        data.length > 0 &&
        data.map((marker, index) => {

            if (!markerArr.includes(marker.location_id)) {
                markerArr.push(marker.location_id)
                return (
                    <CircleMarker
                        eventHandlers={{
                            click: () => {
                                window.open("/dashboarddetail/" + `${marker.location_id}`, "_self")
                            }
                        }}
                        center={[marker.lat, marker.long]}
                        color="green"
                        fillColor="red"
                        radius={20}
                        fillOpacity={0.5}
                        stroke={false}
                    >
                        <Tooltip direction="right" offset={[20, 0]} opacity={1}>
                            <div className='incident-des'>
                                <span className="word-capital"><b>{marker.incident_title != '' ? marker.incident_title : '-'}</b></span>
                                {/* <p><b>Incident Title: </b><span className="word-capital">{marker.incident_title != '' ? marker.incident_title : '-'}</span></p> */}
                            </div>
                            <div className='incident-des2'>

                                <p><b>Artifact: </b><span className="word-capital"> {marker.artifact_name != '' ? marker.artifact_name : '-'}</span></p>
                                <p><b>Phone: </b><span className="word-capital"> +{marker.phone_number != '' ? marker.phone_number : '-'}</span></p>
                                <p><b>Emirate: </b><span className="word-capital"> {marker.emirate != '' ? marker.emirate : '-'}</span></p>
                            </div>
                        </Tooltip>
                    </CircleMarker>


                    // <Marker
                    // eventHandlers={{
                    //     click: () => {
                    //         // map.setView(
                    //         //     [
                    //         //         marker.lat,
                    //         //         marker.long
                    //         //     ],
                    //         //     14
                    //         // );
                    //         // navigate("/dashboarddetail/"+`${marker.location_id}`);
                    //         window.open("/dashboarddetail/" + `${marker.location_id}`, "_blank")
                    //     }
                    // }}
                    //     key={index}
                    //     position={{
                    //         lat: marker.lat,
                    //         lng: marker.long
                    //     }}
                    //     icon={icon}
                    // >

                    // </Marker>

                );
            }
        })
    );
}

function LeafMapView(props) {
    const [map, setMap] = useState(null);
    var dashview, dashview2, mapview, heatmapLayer, imageryview;
    const [mapViewChange, setMapViewChange] = useState(false);
    const [emiratesName, setEmiratesName] = useState('');
    const [zoomLevel, setZoomLevel] = useState();
    var heatmapList = props.data1;

    useEffect(() => {
        props.uaeCityName(emiratesName)
    }, [emiratesName]);

    //Map Start
    const onViewChange = (e) => {
        var viewChange = e.target.value;
        // alert(viewChange)
        if (viewChange === 'dashview') {
            map.getPane('mapview').style.display = 'none';
            map.getPane('dashview').style.display = 'block';
            map.getPane('dashview2').style.display = 'block';
            map.getPane('imageryview').style.display = 'none';

        } else if (viewChange === 'mapview') {
            map.getPane('mapview').style.display = 'block';
            map.getPane('dashview').style.display = 'none';
            map.getPane('dashview2').style.display = 'none';
            map.getPane('imageryview').style.display = 'none';
        } else if (viewChange === 'imageryview') {
            map.getPane('mapview').style.display = 'none';
            map.getPane('dashview').style.display = 'none';
            map.getPane('dashview2').style.display = 'none';
            map.getPane('imageryview').style.display = 'block';
        }
    };

    if (map && ChoroplethLoaded == false) {
        ChoroplethLoaded = true;
        map.createPane('mapview').style.zIndex = 99;
        map.createPane('dashview').style.zIndex = 405;
        map.createPane('dashview2').style.zIndex = 99;
        map.createPane('imageryview').style.zIndex = 99;
        map.on('zoomend', function () {
            setZoomLevel(map.getZoom());
        });
        //map.getPane('mapview').style.zIndex = 99;
        //map.getPane('dashview').style.zIndex = 99;
        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        mapview = L.TileLayer.boundaryCanvas(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
                boundary: uaeJson,
                pane: 'mapview',
                attribution: ''
            }
        );
        map.addLayer(mapview);

        imageryview = L.TileLayer.boundaryCanvas(
            "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
            {
                maxZoom: 22,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                boundary: uaeJson,
                pane: 'imageryview',
                attribution: ''
            }
        );
        map.addLayer(imageryview);
        //Choropleth Code started from Here
        var info = L.control();
        dashview2 = L.geoJson(uaeJson, {
            style: {
                weight: 2,
                opacity: 1,
                color: 'rgba(33, 148, 241, 0.7)',
                dashArray: '0',
                fillOpacity: 0.9,
                fillColor: 'rgba(0,0,0,0.8)'
            },
            pane: 'dashview2'
        }).addTo(map);
        dashview = L.geoJson(uaeJson, {
            style: style,
            onEachFeature: onEachFeature,
            pane: 'dashview'
        }).addTo(map);

        info.onAdd = function (map) {
            this._div = L.DomUtil.create('div', 'info');
            this.update();
            return this._div;
        };
        info.update = function (name) {
            this._div.innerHTML = (name ? "<b>" + name + "</b>" : "");
            setEmiratesName(name)
        };

        info.addTo(map);
        function style(feature) {
            return {
                weight: 2,
                opacity: 0,
                color: 'rgba(33, 148, 241, 0.7)',
                dashArray: '0',
                fillOpacity: 0,
                fillColor: 'rgba(0,0,0,0.8)'
            };
        }

        function highlightFeature(e) {
            var layer = e.target;

            layer.setStyle({
                weight: 2,
                color: 'rgba(33, 148, 241, 1)',
                dashArray: '',
                fillOpacity: 0.5,
                fillColor: 'rgba(33, 148, 241, 0.7)'
            });

            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                layer.bringToFront();
            }
            info.update(layer.feature.properties.NAME_1);
        }
        function clickFeature(e) {
            // dashview.setStyle({
            //     weight: 2,
            //     opacity: 1,
            //     color: 'rgba(33, 148, 241, 0.7)',
            //     dashArray: '0',
            //     fillOpacity: 0.9,
            //     fillColor: 'rgba(0,0,0,0.8)'
            // });
            var layer = e.target;
            if (layer.options.color != 'rgba(33, 148, 241, 0.7)') {
                dashview.setStyle({
                    weight: 2,
                    opacity: 0,
                    color: 'rgba(33, 148, 241, 0.7)',
                    dashArray: '0',
                    fillOpacity: 0,
                    fillColor: 'rgba(0,0,0,0.8)'
                });
                info.update('');

            } else {
                dashview.setStyle({
                    weight: 2,
                    opacity: 0,
                    color: 'rgba(33, 148, 241, 0.7)',
                    dashArray: '0',
                    fillOpacity: 0,
                    fillColor: 'rgba(0,0,0,0.8)'
                });
                layer.setStyle({
                    weight: 2,
                    color: 'rgba(33, 148, 241, 1)',
                    dashArray: '',
                    fillOpacity: 0.5,
                    fillColor: 'rgba(33, 148, 241, 0.9)'
                });
                info.update(layer.feature.properties.NAME_1);

            }

            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                layer.bringToFront();
            }

            // info.update(layer.feature.properties.NAME_1);
        }
        function resetHighlight(e) {
            dashview.resetStyle(e.target);
            info.update();
        }

        function zoomToFeature(e) {
            map.fitBounds(e.target.getBounds());
        }
        function onEachFeature(feature, layer) {
            layer.on({
                // mouseover: highlightFeature,
                // mouseout: resetHighlight,
                //click: zoomToFeature
                click: clickFeature
            });
        }

        //Choropleth Code ended from Here
        map.fitBounds(L.geoJSON(uaeJson).getBounds());
        map.getPane('mapview').style.display = 'none';
        map.getPane('imageryview').style.display = 'none';
    }
    //Map End
    const buttonHandler = () => {
        map.setView([23.4241, 53.8478]);
        map.fitBounds(L.geoJSON(uaeJson).getBounds());
    }

    useEffect(() => {

        if (!map) return;
        // if (mapViewChange) {
        //     // console.log("Map view")
        //     map.getPane('mapview').style.display = 'block';
        //     map.getPane('dashview').style.display = 'none';
        //     map.getPane('dashview2').style.display = 'none';
        //     // document.getElementsByClassName("leaflet-mapview-pane")[0].style.display = 'block';
        //     // // document.getElementsByClassName("leaflet-mapview-pane")[1].style.display = 'block';
        //     // document.getElementsByClassName("leaflet-dashview-pane")[0].style.display = 'none';
        //     // // document.getElementsByClassName("leaflet-dashview-pane")[1].style.display = 'none';
        //     // document.getElementsByClassName("leaflet-dashview2-pane")[0].style.display = 'none';
        //     // document.getElementsByClassName("leaflet-dashview2-pane")[1].style.display = 'none';
        // } else if (!mapViewChange) {
        //     // console.log("Dash view")
        //     map.getPane('mapview').style.display = 'none';
        //     map.getPane('dashview').style.display = 'block';
        //     map.getPane('dashview2').style.display = 'block';
        //     // document.getElementsByClassName("leaflet-mapview-pane")[0].style.display = 'none';
        //     // // document.getElementsByClassName("leaflet-mapview-pane")[1].style.display = 'none';
        //     // document.getElementsByClassName("leaflet-dashview-pane")[0].style.display = 'block';
        //     // // document.getElementsByClassName("leaflet-dashview-pane")[1].style.display = 'block';
        //     // document.getElementsByClassName("leaflet-dashview2-pane")[0].style.display = 'block';
        //     // document.getElementsByClassName("leaflet-dashview2-pane")[1].style.display = 'block';
        // }
        const fetchGeoJSON = async () => {
            if (zoomLevel <= 13 || zoomLevel == undefined) {
                map.getPane('dashview').style.zIndex = 405;
                if (document.getElementsByClassName("leaflet-heatmap-layer")[0]) {
                    document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = 'block';
                }
            } else {
                map.getPane('dashview').style.zIndex = 399;
                if (document.getElementsByClassName("leaflet-heatmap-layer")[0]) {
                    document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = 'none';
                }
            }
            if (heatmapList != '') {
                if (heatData == false) {
                    heatData = true;
                    const points = heatmapList
                        ? heatmapList.map((p) => {
                            if (p['lat'] != null && p['long'] != null) {
                                return [p['lat'], p['long']];
                            } else {
                                return [0, 0];
                            }
                        })
                        : [];

                    if (heatmapLayer && map.hasLayer(heatmapLayer)) {
                        map.removeLayer(heatmapLayer);
                    }
                    heatmapLayer = L.heatLayer(points, {
                        radius: 12,
                        blur: 10,
                        minOpacity: 0.5,
                        maxZoom: 17,
                        minZoom: 5,
                        gradient: {
                            0.5: '#FF581B',
                            0.6: '#F9C20A',
                            0.7: '#F9A90A',
                            0.8: '#F9880A',
                            0.9: '#F9660A',
                            1.0: '#F9450A',
                        }
                    }).addTo(map);
                }
            }
        };
        map.options.minZoom = 7;
        fetchGeoJSON();

    }, [map, heatmapList, zoomLevel]);

    return (
        <>
            <MapContainer
                center={[23.4241, 53.8478]}
                zoom={7}
                ref={setMap}
                // scrollWheelZoom={false}
                zoomControl={false}
            >
                <Control position="topright">
                    <button className="button middle-button button-color-outline mapView" onClick={() => { buttonHandler(true) }}>Reset</button>
                </Control>

                <ZoomControl position='topright' />
                {zoomLevel > 13 &&
                    <Markers data={heatmapList} />
                }
            </MapContainer>
            <div className="sel master-select mapviewvalue" >
                <select name="select-year" id="select-year" onChange={onViewChange}>
                    <option value="dashview">Outline View</option>
                    <option value="mapview">Map View</option>
                    <option value="imageryview">Imagery View</option>
                </select>
            </div>
        </>

    );
}

export default LeafMapView;