import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/UserLogin/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import DashboardDetail from './Components/Dashboard/DashboardDetail';
import Userprofile from './Components/Users/Userprofile';
import Incidents from './Components/Incidents/Incidents';
import Incidentreport from './Components/IncidentReport/Incidentreport';
import Databank from './Components/DataBank/Databank';
import Rssfeed from './Components/RssFeed/Rssfeed';
import Addincident from './Components/Incidents/Addincident';
import Users from './Components/Users/Users';
import Adduser from './Components/Users/Adduser';
import Addquick from './Components/Incidents/Addquick';
import Editincident from './Components/Incidents/Editincident';
import Viewincident from './Components/Incidents/Viewincident';
import Changedpassword from './Components/Users/Changedpassword';
import ForgotPassword from './Components/UserLogin/Forgot-password';
import Incidentreportlist from './Components/IncidentReport/Incidentreportlist';
import LoginCode from './Components/UserLogin/LoginCode';
import Viewuser from './Components/Users/Viewuser';
//import { getUrl } from './utils/Urlpath';
import ProtectedRoute from './ProtectedRoute';
import Reset from './Reset';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuditLog from './Components/AuditLog/AuditLog';
import Viewlog from './Components/AuditLog/Viewlog';
import Configuration from './Components/Configuration/Configuration';
import Addartifacttype from './Components/ArtifactType/Addartifacttype';
import Artifacttypes from './Components/ArtifactType/Artifacttypes';
import Emailtemplate from './Components/EmailTemplate/emailtemplate';
import EditEmailtemplate from './Components/EmailTemplate/editemailtemplate';

import Artifactrating from './Components/ArtifactRating/Artifactrating';
import Addartifactrating from './Components/ArtifactRating/Addartifactrating';
import Personlevels from './Components/PersonLevel/Personlevels';
import Addpersonlevel from './Components/PersonLevel/Addpersonlevel';
import EmirateList from './Components/Emirate/EmirateList';
import Editemirate from './Components/Emirate/Editemirate';
import AppSetting from './Components/AppSetting/AppSetting';
import Editartifacttype from './Components/ArtifactType/Editartifacttype';
import Editartifacterating from './Components/ArtifactRating/Editartifactrating';
import Editpersonlevel from './Components/PersonLevel/Editpersonlevel';
import EditUser from './Components/Users/Edituser';
import { getToken } from './utils/Common';



function App() {
  const isAuthenticated = getToken();
  //alert(isAuthenticated);
  var role = localStorage.getItem("role");
  //const url = getUrl();
  //const timerId = setInterval(() => {
  // refreshToken();
  //}, 1800000);
  //}, 1800000);

  // const refreshToken = () => {
  //   if(getToken()){
  //     axios.get(url+'get-token', {
  //       headers: {
  //         'Authorization': 'Bearer '+getToken()
  //       }
  //     })
  //     .then(res => {
  //       // console.log('token')
  //       // console.log(res.data.ResponseData.data);
  //       setToken(res.data.ResponseData.data);
  //     })
  //     .catch(err => {
  //       //console.log(err.ResponseData.error.profile_image['0']);
  //       //toast.error("The profile image must be a file of type: jpg, png, jpeg");
  //     })
  //   }
  // }
  // refreshToken();



  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <div className="content">
            <Routes>
              {/* <Route path="/" element={<Login />} /> */}
             
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route path="/dashboarddetail/:id" element={<DashboardDetail />} /> */}
              <Route path="/dashboard" element={<Dashboard />} />
              {/* <Route path="/dashboarddetail/:id" element={<ProtectedRoute component={DashboardDetail} element={<DashboardDetail />} />} />
              <Route path="/userprofile" element={<ProtectedRoute component={Userprofile} element={<Userprofile />} />} />
              <Route path="/incidents" element={<ProtectedRoute component={Incidents} element={<Incidents />} />} />
              <Route path="/incidentreport" element={<ProtectedRoute component={Incidentreport} element={<Incidentreport />} />} />
              <Route path="/incident-report-list" element={<ProtectedRoute component={Incidentreportlist} element={<Incidentreportlist />} />} />
              <Route path="/databank" element={<ProtectedRoute component={Databank} element={<Databank />} />} />
              <Route path="/incidents" element={<ProtectedRoute component={Incidents} element={<Incidents />} />} />
              <Route path="/addincident" element={<ProtectedRoute component={Addincident} element={<Addincident />} />} />
              <Route path="/adduser" element={<ProtectedRoute component={Adduser} element={<Adduser />} />} />
              <Route path="/addquick" element={<ProtectedRoute component={Addquick} element={<Addquick />} />} />
              <Route path="/getotp" element={<LoginCode />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/resetpassword/:token/:email" element={<Reset />} />
              <Route path="/edituser/:id" element={<ProtectedRoute component={EditUser} element={<EditUser />} />} />
              <Route path="/viewuser/:id" element={<ProtectedRoute component={Viewuser} element={<Viewuser />} />} />
              {role !== 'Agent' &&
                <>
                  <Route path="/rssfeed" element={<ProtectedRoute component={Rssfeed} element={<Rssfeed />} />} />
                  <Route path="/users" element={<ProtectedRoute component={Users} element={<Users />} />} />
                  <Route path='/audit' element={<ProtectedRoute component={AuditLog} element={<AuditLog />} />} />
                  <Route path="/configurations" element={<ProtectedRoute component={Configuration} element={<Configuration />} />} />
                  <Route path="/emailtemplate" element={<ProtectedRoute component={Emailtemplate} element={<Emailtemplate />} />} />
                  <Route path="/editemailtemplate/:id" element={<ProtectedRoute component={EditEmailtemplate} element={<EditEmailtemplate />} />} />
                  <Route path='/viewlog/:id' element={<ProtectedRoute component={Viewlog} element={<Viewlog />} />} />
                  <Route path="/artifacttypes" element={<ProtectedRoute component={Artifacttypes} element={<Artifacttypes />} />} />
                  <Route path="/addartifacttypes" element={<ProtectedRoute component={Addartifacttype} element={<Addartifacttype />} />} />
                  <Route path="/editartifacttypes/:id" element={<ProtectedRoute component={Editartifacttype} element={<Editartifacttype />} />} />
                  <Route path="/appsetting" element={<ProtectedRoute component={AppSetting} element={<AppSetting />} />} />
                  <Route path="/artifactrating" element={<ProtectedRoute component={Artifactrating} element={<Artifactrating />} />} />
                  <Route path="/addartifactrating" element={<ProtectedRoute component={Addartifactrating} element={<Addartifactrating />} />} />
                  <Route path="/editartifactrating/:id" element={<ProtectedRoute component={Editartifacterating} element={<Editartifacterating />} />} />
                  <Route path="/personlevels" element={<ProtectedRoute component={Personlevels} element={<Personlevels />} />} />
                  <Route path="/addpersonlevel" element={<ProtectedRoute component={Addpersonlevel} element={<Addpersonlevel />} />} />
                  <Route path="/editpersonlevel/:id" element={<ProtectedRoute component={Editpersonlevel} element={<Editpersonlevel />} />} />
                  <Route path="/emirates" element={<ProtectedRoute component={EmirateList} element={<EmirateList />} />} />
                  <Route path="/editemirate/:id" element={<ProtectedRoute component={Editemirate} element={<Editemirate />} />} />
                </>
              }
              <Route path="/editincident/:id" element={<ProtectedRoute component={Editincident} element={<Editincident />} />} />
              <Route path="/viewincident/:id" element={<ProtectedRoute component={Viewincident} element={<Viewincident />} />} />
              <Route path="/change-password" element={<ProtectedRoute component={Changedpassword} element={<Changedpassword />} />} />
              <Route path="/databank/:id" element={<ProtectedRoute component={Databank} element={<Databank />} />} /> */}
              <Route
                path="*"
                element={<Navigate to="/dashboard" replace />}
              />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;