import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setUserSession } from '../../utils/Common';
import "../../styles.css";
import { getUrl } from '../../utils/Urlpath';
import Video from '../../assets/video/Login-page-Background.mp4';

function Login() {
  const url = getUrl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { message } = state || {};
  const [loading, setLoading] = useState(false);
  const email = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState('');
  //let [siteconfig, setsiteconfig] = useState([]);
  const title = "Incident Tracker 2 - Login";
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
    if (message) {
      setError(message);
    }
  }, [title,message]);

  // handle button click of login form
  const handleLogin = (e) => {
    e.preventDefault();
    //setError(null);
    localStorage.removeItem("profile_image");
    localStorage.removeItem("site_logo");
    if (email.value === '') {
      setErrorMessages({ name: "email", message: errors.uname });
    } else if (email.value !== '') {
      // eslint-disable-next-line
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(email.value) === false) {
        setErrorMessages({ name: "email", message: errors.invalid });
      } else {
        setErrorMessages({ name: "email", message: '' });
      }

    }
    if (password.value === '') {
      setErrorMessagesemail({ name: "pass", message: errors.pass });
    } else {
      setErrorMessagesemail({ name: "pass", message: '' });
    }
    if (email.value !== '' && password.value !== '') {
      setLoading(true);
      setErrorMessagesemail({});
      setErrorMessages({});

      axios.post(url + 'login', { email: email.value, password: password.value }).then(response => {
        setLoading(false);
        if (response?.data?.ResponseStatus === 0) {
          localStorage.setItem("email", email.value);
          navigate("/getotp");
        }
        if (response?.data?.ResponseStatus === 1) {
          console.log(response.data.ResponseData.menuConfig.module_dashboard)
          setUserSession(response.data.ResponseData.token, response.data.ResponseData.id);
          localStorage.setItem("profile_image", response.data.ResponseData.profile_image);
          localStorage.setItem("site_name", response.data.ResponseData.site_name);
          localStorage.setItem("site_logo", response.data.ResponseData.site_logo);
          localStorage.setItem("role", response.data.ResponseData.roles[0].name);
          localStorage.setItem("module_dashboard", response.data.ResponseData.menuConfig.module_dashboard);
          localStorage.setItem("module_manage_incident", response.data.ResponseData.menuConfig.module_manage_incident);
          localStorage.setItem("module_incident_report", response.data.ResponseData.menuConfig.module_incident_report);
          localStorage.setItem("module_data_bank", response.data.ResponseData.menuConfig.module_data_bank);
          localStorage.setItem("module_abcd_rss_feed", response.data.ResponseData.menuConfig.module_abcd_rss_feed);
          localStorage.setItem("module_user", response.data.ResponseData.menuConfig.module_user);
          localStorage.setItem("module_configuration", response.data.ResponseData.menuConfig.module_configuration);
          localStorage.setItem("module_audit_log", response.data.ResponseData.menuConfig.module_audit_log);
          localStorage.setItem("module_artifact_type", response.data.ResponseData.menuConfig.module_artifact_type);
          localStorage.setItem("module_artifact_rating", response.data.ResponseData.menuConfig.module_artifact_rating);
          localStorage.setItem("module_person_level", response.data.ResponseData.menuConfig.module_person_level);
          localStorage.setItem("module_emirate", response.data.ResponseData.menuConfig.module_emirate);
          navigate("/dashboard");
        }
        axios.post(url + 'update-app-site-config', {

        }).then(res => {
          //setsiteconfig(res.data.ResponseData.data.siteConfigList.site_logo);
          localStorage.setItem("site_logo", response.data.ResponseData.data.site_logo);
        })
      }).catch(error => {
        setLoading(false);
        // if (typeof error.response.data.ResponseData.error.email !== "undefined") {
        //   setErrorMessagesemail(error.response.data.ResponseData.error[0]);
        // }
        // if (typeof error.response.data.ResponseData.error.password !== "undefined") {
        //   setErrorMessagesemail(error.response.data.ResponseData.error[1]);
        // }
           setError('Invalid Login details');
      });
    }
  }

  const [errorMessages, setErrorMessages] = useState({});
  const [errorMessagesEmail, setErrorMessagesemail] = useState({});

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error loginerr">{errorMessages.message}</div>
    );

  const renderErrorMessageEmail = (name) =>
    name === errorMessagesEmail.name && (
      <div className="error loginerr">{errorMessagesEmail.message}</div>
    );


  const errors = {
    uname: "Please enter email",
    pass: "Please enter password",
    invalid: "Please enter valid email"
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  return (
    <div className="wrapper">
      <video autoplay="" loop muted>
        <source src={Video} type="video/mp4" />
      </video>
      <div id="login-page">
        <div className="card login-card form" >
          <h1 className="title">Login</h1>
          <form id="login-form" >
            {error ? <div className="msg-error">{error}</div> : ''}
            <div className="form-item">
              <label className="form-label">Email ID</label>
              <input className="form-input" autoFocus placeholder="Type your email" name="email" {...email} type="email" required="" />
              {renderErrorMessage("email")}
            </div>
            <div className="form-item">
              <label className="form-label">Password</label>
              <input className="form-input js-visible-password" placeholder="Type your password" {...password} name="password" type={passwordType} required="" />
              <button className="no-button psw-show-button" type="button" data-action="show-password" onClick={togglePassword}>
                {passwordType === 'password' ? <i className="icon eye-icon"></i> : <i className="icon eye-slash-icon"></i>}
              </button>
              {renderErrorMessageEmail("pass")}
            </div>
            <div className="form-submit set-content">
              <button className="button middle-button button-color" type="submit" onClick={handleLogin} disabled={loading}><i className="icon login-icon"></i> {loading ? 'Loading...' : 'Login'}</button>
              {/* <button className="button middle-button button-color-outline" type="reset"><i className="icon cross-circle-icon"></i> Cancel</button> */}
              {/* <Button  type="button" onClick={handleLogin} disabled={loading}><i className="icon login-icon"></i> {loading ? 'Loading...' : 'Login'}</Button>
              <Button variant="color-outline" size={"middle-button"} type="reset"><i className="icon cross-circle-icon"></i> Cancel</Button> */}
              <Link to="/forgotpassword">Forgot your password?</Link>
            </div>
          </form>
        </div>
      </div>
    </div> 
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;